import React from 'react'
import Layout from '../components/Layout'
import * as styles from '../styles/project-details.module.css'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

const ProjectDetails = ({data}) => {
    const { html } = data.markdownRemark
    const { title, stack, featuredImg } = data.markdownRemark.frontmatter
    return (
        <Layout>
            <div className={styles.details}>
                <h2> {title} </h2>
                <br /> 
                <h3> {stack} </h3>
                <div className={styles.featured}>
                    <Img fluid={featuredImg.childImageSharp.fluid} />
                </div>
                <br></br>
                <div className={styles.html} dangerouslySetInnerHTML={{__html: html }} />
            </div>
        </Layout>

    )
}

export const query = graphql`
    query ProjectDetails($slug: String) {
        markdownRemark(frontmatter: {slug: {eq: $slug}}) {
            html
                frontmatter {
                    stack
                    title
                        featuredImg {
                        childImageSharp {
                            fluid {
                               ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `           

export default ProjectDetails
